.room {
  position: relative;
}

.local-participant {
  text-align: center;
  margin-bottom: 2em;
}
.remote-participants {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  padding: 0 2em 2em;
}
.participant {
  background: #333e5a;
  padding: 5px;
  border-radius: 6px;
  display: inline-block;
  width: 100%;
  height: -webkit-fill-available;
  height: 100%;
  min-height: 172px;
}
.participant:last-child {
  margin-right: 0;
}

video {
  width: 100%;
  display: block;
  border-radius: 6px;
  height: -webkit-fill-available;
  height: 100%;
  object-fit: contain;
  transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg); /* Safari and Chrome */
  -moz-transform: rotateY(180deg); /* Firefox */
}

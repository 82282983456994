.section-heading {
  @apply text-green text-3xl font-bold;
}

.section-subheading {
  @apply text-darkBlue font-bold text-xl mb-9;
}

.subheading {
  @apply text-xl font-semibold text-text;
}

.search-input {
  background: url('../../assets/icons/search-icon.svg') no-repeat scroll 10px 50%;
  padding-left: 30px;
  @apply pl-7 py-1 mb-2 w-full border bg-white border-lightBorder rounded-lg placeholder-text outline-none;
}

.patientborder {
  border-top: 2px solid #e5e7eb;
}
.headerslide {
  z-index: 1;
}
div button.generalhealth {
  display: contents;
}
.general-patient {
  displaynp-: flex;
}
.general-patient div {
  margin: auto 8px;
}

.header-checkbox-report {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: baseline;
}

.header-button-report {
  display: flex;
  justify-content: space-between;
}

.staffpopupnote {
  display: flex;
}
.header-checkbox-report div {
  border-radius: 50px;
  padding: 3px;
}
.header-checkbox-report [type='checkbox']:checked {
  background-color: #64ccc9;
  border: 1px solid #64ccc9;
  color: #fff;
  opacity: 1;
  border-radius: 50px;
  padding: 3px;
}

.notification-checkbox [type='checkbox']:checked {
  background-color: #64ccc9;
  border: 1px solid #64ccc9;
  color: #fff;
  opacity: 1;
}

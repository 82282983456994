.card {
  min-height: 9rem;
  min-width: 20rem;
  width: 28.5%;
  @apply rounded-xl mr-8 p-8 pt-6 border-2 mb-1 border-green bg-white cursor-pointer transition-all duration-300;
}

.card * {
  @apply transition-all duration-300;
}

.card:hover {
  @apply bg-green;
}
.card:hover * {
  @apply text-white;
}

.case-title {
  width: 11rem;
  min-width: fit-content;
  @apply inline-block text-xl font-semibold mr-4;
}

.lab-title {
  font-size: 1.375rem;
  font-weight: 500;
  @apply text-appointmentPrimary;
}

.table-heading {
  @apply text-2xl text-black font-semibold;
}

body {
  background-color: #fafbfc;
}

.rbc-allday-cell {
  display: none;
}

.rbc-header + .rbc-header {
  border: none;
}

.rbc-time-view {
  border: none;
}

.rbc-time-slot {
  flex: none;
}

.rbc-day-slot .rbc-events-container {
  margin-right: 0;
}

.rbc-time-content > * + * > * {
  border-left: none;
  border-right: 1px solid #ddd;
}
.rbc-row {
  border: none;
}
.rbc-time-header-content {
  border-right: none;
  border-left: none;
}
.rbc-time-header {
  border: none;
}
.rbc-header {
  color: #9fa3a3;
}
.rbc-header {
  border: none;
}

.rbc-header,
.rbc-header.rbc-today {
  height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
}

.rbc-header {
  margin-bottom: 0.625rem;
}

.rbc-header > a > span {
  color: #444444;
  font-weight: 700;
  font-size: 1.125rem;
}
.rbc-event {
  padding: 2px;
  border: 1px solid transparent !important;
}
.rbc-event:hover {
  z-index: 1;
  border-color: rgba(0, 0, 0, 0.1) !important;
}
.rbc-day-slot .rbc-event {
  overflow: visible;
}
.rbc-event .rbc-selected {
  width: 10px;
  margin-left: auto;
  margin-right: auto;
  z-index: 5 !important;
}

.rbc-today {
  color: #fff;
  background-color: rgba(255, 253, 249);
}

.rbc-today > a {
  background-color: #00698b;
  padding: 0.625rem 1rem;
  border-radius: 20px;
}

.rbc-today > a > span {
  color: #fff;
}

.rbc-current-time-indicator {
  background-color: #f2355b;
  left: auto;
  width: 100%;
  height: 3px;
  z-index: 5;
}

.rbc-event-label {
  display: none;
}

.rbc-event-content {
  text-overflow: ellipsis;
  overflow: visible;
  white-space: nowrap;
  font-weight: 500;
  display: flex;
  align-items: center;
  color: #fff;
}

.rbc-label {
  font-weight: 500;
  color: #9fa3a3;
}

.rbc-timeslot-group {
  min-height: 4rem;
}

.cal-availability {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  padding-right: 2.5rem;
}
.bigcalkey {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
}
input:checked ~ .dot {
  transform: translateX(100%);
  background-color: #fff;
}
.calpopup {
  padding-right: 17px;
  padding-left: 17px;
  padding-top: 14px;
  padding-bottom: 0px;
  width: 214px;
  border-radius: 0.8rem;
  margin-top: 96px;
}

.settpopups {
  padding-right: 2px;
  padding-left: 17px;
  padding-top: 14px;
  padding-bottom: 0px;
  width: 300px;
  border-radius: 0.8rem;
  top: 0;
  height: 60vh;
  overflow: auto;
}
.popupbig {
  background-color: #64ccc9;
}
.popupbigcal {
  background-color: #00698b;
}
.bigcalarrow.arrow-up {
  height: 33px;
  top: 99%;
  transform: rotate(180deg);
  left: 33%;
}

*::after {
  box-sizing: border-box;
}

.label {
  display: inline-grid;
  position: relative;
}

.label::after {
  max-height: 5rem;
  grid-area: 1 / 1;
  content: attr(data-value) ' ';
  visibility: hidden;
  white-space: pre-wrap;
  overflow: auto;
}

.textarea {
  grid-area: 1 / 1;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

/* Fonts */
@layer base {
  @font-face {
    font-family: 'Greycliff CF';
    font-weight: 100;
    src: url('assets/fonts/GreycliffCF/GreycliffCF-Thin.woff') format('woff'),
      url('assets/fonts/GreycliffCF/GreycliffCF-Thin.woff2') format('woff2');
  }

  @font-face {
    font-family: 'Greycliff CF';
    font-weight: 200;
    src: url('assets/fonts/GreycliffCF/GreycliffCF-ExtraLight.woff') format('woff'),
      url('assets/fonts/GreycliffCF/GreycliffCF-ExtraLight.woff2') format('woff2');
  }

  @font-face {
    font-family: 'Greycliff CF';
    font-weight: 300;
    src: url('assets/fonts/GreycliffCF/GreycliffCF-Light.woff') format('woff'),
      url('assets/fonts/GreycliffCF/GreycliffCF-Light.woff2') format('woff2');
  }

  @font-face {
    font-family: 'Greycliff CF';
    font-weight: 400;
    src: url('assets/fonts/GreycliffCF/GreycliffCF-Regular.woff') format('woff'),
      url('assets/fonts/GreycliffCF/GreycliffCF-Regular.woff2') format('woff2');
  }

  @font-face {
    font-family: 'Greycliff CF';
    font-weight: 500;
    src: url('assets/fonts/GreycliffCF/GreycliffCF-Medium.woff') format('woff'),
      url('assets/fonts/GreycliffCF/GreycliffCF-Medium.woff2') format('woff2');
  }

  @font-face {
    font-family: 'Greycliff CF';
    font-weight: 600;
    src: url('assets/fonts/GreycliffCF/GreycliffCF-DemiBold.woff') format('woff'),
      url('assets/fonts/GreycliffCF/GreycliffCF-DemiBold.woff2') format('woff2');
  }

  @font-face {
    font-family: 'Greycliff CF';
    font-weight: 700;
    src: url('assets/fonts/GreycliffCF/GreycliffCF-DemiBold.woff') format('woff'),
      url('assets/fonts/GreycliffCF/GreycliffCF-DemiBold.woff2') format('woff2');
  }

  @font-face {
    font-family: 'Greycliff CF';
    font-weight: 800;
    src: url('assets/fonts/GreycliffCF/GreycliffCF-ExtraBold.woff') format('woff'),
      url('assets/fonts/GreycliffCF/GreycliffCF-ExtraBold.woff2') format('woff2');
  }

  @font-face {
    font-family: 'Greycliff CF';
    font-weight: 900;
    src: url('assets/fonts/GreycliffCF/GreycliffCF-Heavy.woff') format('woff'),
      url('assets/fonts/GreycliffCF/GreycliffCF-Heavy.woff2') format('woff2');
  }
}
input:focus {
  --tw-ring-color: transparent !important;
}
input:focus {
  outline: none !important;
}
input:active {
  outline: none !important;
}

/* buttons */

@layer components {
  .btn {
    @apply min-w-fit font-bold text-base py-1 px-5 active:opacity-80;
  }
  .btn-primary {
    @apply text-white bg-darkBlue;
  }

  .btn-secondary {
    @apply text-darkBlue bg-lightGreyBackground;
  }

  .btn-depressed {
    @apply text-text underline bg-transparent;
  }

  .btn-success {
    @apply text-white bg-green;
  }

  /* End styles for buttons */
}
html {
  font-size: 0.75rem;
}
body {
  font-weight: 500;
  margin: 0 !important;
}
.arrow-up {
  position: absolute;
  top: -2em;
  height: 2em;
}

._loading_overlay_spinner {
  width: '100px' !important;
}
._loading_overlay_spinner svg circle {
  stroke: #37a5f3 !important;
}
._loading_overlay_overlay {
  background-color: rgba(248, 246, 246, 0.3) !important;
}

@media screen and (min-width: 768px) {
  .arrow-up {
    /*left: 36%;*/
  }
}

@media screen and (min-width: 1536px) {
  .arrow-up {
    height: 2em;
    top: -2em;
  }
}

/* sidebar */

@layer components {
  .sidebar-link__icon {
    @apply h-7 w-7;
  }
}

@layer utilities {
  @variants responsive {
    .min-modal-size {
      width: 600px;
    }
    .max-modal-size {
      width: 720px;
    }
  }
}

.sidebar-link-active .icon {
  @apply bg-green text-white;
}

.standard-table {
  max-height: 200px;
}

.queue-list {
  max-height: 400px;
}

.w-sb {
  width: 18.5%;
  min-width: 220px;
}
.m-sb {
  margin-left: max(18.5%, 220px);
}

.dashboard-label {
  font-size: 0.625rem;
  line-height: normal;
}

.css-tlr239-control {
  @apply border-lightBorder !important;
}

.tox {
  border-radius: 8px !important;
}

span,
div,
p,
h1,
h2,
h3,
h4,
h5,
h6,
a {
  font-family: 'Greycliff CF', Arial, Helvetica, sans-serif;
  font-size: 1rem;
  box-sizing: border-box;
  @apply text-text;
}

.appoint-picker .DayPicker-NavButton {
  top: 9px;
  right: 0;
}
.appoint-picker .DayPicker-Caption {
  padding: 0 0;
}
.appoint-picker .DayPicker-Month {
  margin: 0;
}
.appoint-picker .DayPicker-WeekdaysRow,
.appoint-picker .DayPicker-Week {
  justify-content: space-between;
}
.appoint-picker .DayPicker-Day {
  font-weight: 500;
}

@media screen and (min-width: 1800px) {
  html {
    font-size: 0.875rem;
  }
}

@keyframes inAnimation {
  0% {
    opacity: 0;
    visibility: hidden;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}

@keyframes outAnimation {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}

/* checkbox */
input:checked + svg {
  display: block;
}

.w-notification {
  max-width: 30.4rem;
}

.adminauto {
  width: 700px;
}

.adminauto label {
  width: 20%;
}
.adminauto input {
  width: 100%;
}
.adminautomodal {
  margin-left: 25%;
}
.requestlist input {
  width: 100%;
}
.requestlist label {
  width: 31%;
}
.requestlisttb {
  width: 700px;
}
.requestlist .css-2b097c-container {
  width: 100%;
}
.requestdrop .css-15wxy9z-control {
  width: 65%;
}
.uploadbox {
  display: flex;
  margin-bottom: 12px;
  flex-wrap: nowrap;
}
.uploadbox span {
  margin-right: 4rem;
}
.appointment-popup .css-1rmc9e2-singleValue {
  color: #37a5f3;
}
.adminapplist {
  width: 700px;
}
.admin-popup input {
  width: 100%;
}
.admin-popup label {
  width: 31%;
}
.admin-popup .css-2b097c-container {
  width: 100%;
}
.multiselect-admin label {
  margin-right: 2.7rem;
}
.patientmsgup {
  width: 700px;
}

.patientmsgup input {
  width: 100%;
}
/* .listpopmsg .css-15wxy9z-control{
  width: 50%;
} */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.css-9gakcf-option {
  background-color: #e0f4f4 !important;
  color: #00698b !important;
}

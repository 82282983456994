@import 'react-day-picker/lib/style.css';

.DayPicker-Day--selected:not(.DayPicker-Day--disabled) {
  background-color: #00698b !important;
}

.DayPicker-Day {
  width: 30px;
  height: 30px;
  line-height: normal;
}

@media screen and (min-width: 1536px) {
  .DayPicker-Day {
    width: 40px;
    height: 40px;
    line-height: 1.5rem;
  }
}

.DayPicker-Day--disabled {
  color: #3d3d3d;
}

.DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected) {
  background: #e0f4f4 !important;
  color: #00698b;
}

.DayPicker-Caption {
  font-size: 1.5rem;
  color: #3d3d3d;
  font-family: 'Greycliff CF';
}

.DayPicker-wrapper {
  position: relative;
  flex-direction: row;
  user-select: none;
  width: 100%;
  margin: 0;
  padding: 0;
}

.DayPicker-Months {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
}

.DayPicker-Month {
  margin-top: 0;
}

.DayPicker-NavBar {
  position: relative;
  margin: 0;
  padding: 0;
}

.DayPicker-NavButton:hover {
  opacity: 0.8;
}

.DayPicker-WeekdaysRow {
  display: flex;
  justify-content: space-evenly;
  margin: 0;
  padding: 0;
}

.DayPicker-Week {
  display: flex;
  justify-content: space-evenly;
  margin: 0;
  padding: 0;
}

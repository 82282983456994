.css-1pahdxg-control,
.css-yk16xz-control {
  min-height: 26px !important;
  /* height: 26px !important; */
  background-color: #fafbfc !important;
  border: none !important;
  box-shadow: none !important;
}

.css-1wy0on6,
.css-tlfecz-indicatorContainer,
.css-1hb7zxy-IndicatorsContainer {
  display: none !important;
}

.css-1hwfws3 {
  min-height: 26px !important;
}
.searchOptions .css-1pahdxg-control,
.searchOptions .css-yk16xz-control,
.searchOptions .css-g1d714-ValueContainer {
  background-color: #f4f5f8 !important;
  padding: 0;
  /* height: 26px !important; */
  min-height: 26px !important;
}

.css-1wa3eu0-placeholder {
  color: #00698b !important;
  font-weight: 500 !important;
}
